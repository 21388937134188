import Vue from 'vue'
import moment from 'moment'

import PublicAPI from '@/api/public'
import MemberAPI from '@/api/member'

// import utils
import zenkakuToHankaku from '@/helpers/utils/zenkakuToHankaku'

const checkStoreable = (prop) => {
    switch (prop) {
        case 'professionalLevelSkills':
        case 'businessSkills':
        case 'basicLevelSkills':
            return false;
    }

    return true
}

const buildPostProfileParams = (state, getters) => {
    console.log(getters)
    const params = {
        // Step1
        profileImage: state.profileImage,
        memberKind: state.memberKind, // 2.個人 1.法人
        companyName: state.memberKind == 2 ? null : state.companyName,
        companyNameKana: state.memberKind == 2 ? null : state.companyNameKana,
        presidentName: state.memberKind == 2 ? null : state.presidentName,
        presidentNameKana: state.memberKind == 2 ? null : state.presidentNameKana,
        familyNameKana: state.familyNameKana,
        nameKana: state.nameKana,
        familyName: state.familyName,
        name: state.name,
        useNameAsDisplayName: state.useNameAsDisplayName,
        displayName: state.useNameAsDisplayName ? null : state.displayName,
        gender: state.gender, // 1. 男性 2. 女性 0. 未選択
        birthdate: state.birthdate, // 
        zip: state.zip,
        prefCd: state.prefCd,
        city: state.city,
        address: state.address,
        usePersonalLocationAsOfficeLocation: state.memberKind == 2 ? null : state.usePersonalLocationAsOfficeLocation,
        officeZip: state.memberKind == 2 ? null : (state.usePersonalLocationAsOfficeLocation ? state.zip : state.officeZip),
        officePrefCd: state.memberKind == 2 ? null : (state.usePersonalLocationAsOfficeLocation ? state.prefCd : state.officePrefCd),
        officeCity: state.memberKind == 2 ? null : (state.usePersonalLocationAsOfficeLocation ? state.city : state.officeCity),
        officeAddress: state.memberKind == 2 ? null : (state.usePersonalLocationAsOfficeLocation ? state.address : state.officeAddress),
        tel: state.tel,

        // Step2
        employeeTypes: state.employeeTypes,
        unitPrice: state.unitPrice - 0,
        workingTimes: state.workingTimes,
        jobCategories: state.jobCategories,
        locations: state.locations,
        workStyles: state.workStyles,

        // Step3
        professionalLevelSkills: state.professionalLevelSkills,
        businessLevelSkills: state.businessLevelSkills,
        basicLevelSkills: state.basicLevelSkills,
        certifications: state.certifications,

        // Step4
        workExperiences: state.workExperiences,

        // Step5
        catchPhrase: state.catchPhrase,
        selfPromotion: state.selfPromotion,

        // Step6
        selfChecks: state.selfChecks, // radio コードのみ
        fromRegister: state.fromRegister,

        // Portfolios
        portfolios: (getters && getters.isPortfolioBlank) ? [] : state.portfolios,

        mailMagazine: state.mailMagazine,
        scout: state.scout,
        newMail: typeof state.newMail == 'undefined' ? false : state.newMail,

        // 経路分析
        rt: state.rt,

        // 追加質問
        additionalQuestions: state.additionalQuestions.length > 0 ? state.additionalQuestions : [], // radio コードのみ
        
        // カスタム設問回答
        customQuestionAnswers: state.customQuestionAnswers.length > 0 ? state.customQuestionAnswers : [],

        currentProfileStep: 1
    }

    return params
};

export default {
    namespaced: true,

    state: {
        loaded: false,
        birthdate: null,

        // STEP1
        profileImage: null,
        memberKind: {
            'code': '01',
            'name': '個人',
        },
        companyName: "",
        companyNameKana: "",
        presidentName: "",
        presidentNameKana: "",
        familyNameKana: "",
        nameKana: "",
        familyName: "",
        name: "",
        useNameAsDisplayName: false,
        displayName: "",
        gender: {
            'code': '',
            'name': '',
        },
        zip: "",
        prefCd: "",
        city: "",
        address: "",
        usePersonalLocationAsOfficeLocation: false,
        officeZip: "",
        officePrefCd: "",
        officeCity: "",
        officeAddress: "",
        tel: "",

        // STEP2
        employeeTypes: [],
        unitPrice: "",
        workingTimes: [],
        jobCategories: [],
        locations: [],
        workStyles: [],

        // STEP3
        professionalLevelSkills: [],
        businessLevelSkills: [],
        basicLevelSkills: [],
        certifications: [],

        certificationText: "",

        // STEP4
        workExperiences: [],

        // STEP5
        catchPhrase: "",
        selfPromotion: "",

        // STEP6
        selfChecks: [],

        // 追加質問（正社員関連など）
        additionalQuestions:[],

        // カスタム設問
        customQuestionAnswers:[],

        // 会員登録時のプロフィール情報更新かどうかの判断
        fromRegister: false,

        // Portfolio
        portfolios: [],
        portfolioUpdateFlag: false,

        // Mails
        mailMagazine: true,
        scout: true,
        newMail: true,

        // controlls
        temporaryProfileId: null,
        updateFlg: false,
        updateOnlyPortfolioFlg: false,
        showVerifyModalFlg: false,
        showNDAModalFlg: false,
        showNDAMessageFlg: false,

        // 本人確認状況
        verifyStatus: null,
        bankAccountStatus: null,

        unitPriceMin: null,
        unitPriceMax: null,

        // spモーダル表示
        showSpModalBack: false,

        // ヘッダーの未読判定
        messageUnreadFlg: false,

        // cookieからの経路判定
        rt: null,
        memberId: null,

        // SNSから来たかの判定
        fromSNS: false,

        // 離脱ブロックのトリガー
        isPreventDefection: false,
        // NPS回答モーダル表示判定
        displayNps: false,


        // 前ページへ戻ってきた時のフラグ
        isFromNextPage: false,

        // ユーザー評価
        evaluateAverage: null,
				// エントリー経由か判定
        viaEntry: false,
    },
    mutations: {
        setCurrentStep(state,v){
            state.currentProfileStep = v
        },
        setBirthdate(state, v) {
            state.birthdate = v;
        },
        // STEP1
        setProfileImage(state, v) {
            state.profileImage = v
        },
        setMemberKind(state, v) {
            state.memberKind = v
        },
        setCompanyName(state, v) {
            // 文字クラス\sで定義される全ての空白文字から全角文字を除いた正規表現で全角空白以外の空白文字を削除(MYF_2-802)
            // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Regular_expressions/Character_class_escape#%E8%A7%A3%E8%AA%AC
            // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Lexical_grammar#%E3%83%9B%E3%83%AF%E3%82%A4%E3%83%88%E3%82%B9%E3%83%9A%E3%83%BC%E3%82%B9
            // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Lexical_grammar#%E6%94%B9%E8%A1%8C%E6%96%87%E5%AD%97
            state.companyName = v.toString().replace(/[\t\v\f\u0020\u1680\u2000-\u2006\u2008-\u200A\u205F\u00A0\u2007\u202F\uFEFF\n\r\u2028\u2029]+/g,'')
        },
        setCompanyNameKana(state, v) {
            state.companyNameKana = v.toString().replace(/\s+/g,'')
        },
        setPresidentName(state, v) {
            state.presidentName = v.toString().replace(/\s+/g,'')
        },
        setPresidentNameKana(state, v) {
            state.presidentNameKana = v.toString().replace(/\s+/g,'')
        },
        setFamilyNameKana(state, v) {
            state.familyNameKana = v.toString().replace(/\s+/g,'')
        },
        setNameKana(state, v) {
            state.nameKana = v.toString().replace(/\s+/g,'')
        },
        setFamilyName(state, v) {
            state.familyName = v.toString().replace(/\s+/g,'')
        },
        setName(state, v) {
            state.name = v.toString().replace(/\s+/g,'')
        },
        setUseNameAsDisplayName(state, v) {
            state.useNameAsDisplayName = v
        },
        setDisplayName(state, v) {
            state.displayName = v.toString().replace(/\s+/g,'')
        },
        setGender(state, v) {
            state.gender = v
        },
        setZip(state, v) {
            state.zip = zenkakuToHankaku(v)
        },
        setPrefCd(state, v) {
            state.prefCd = v
        },
        setCity(state, v) {
            state.city = v
        },
        setAddress(state, v) {
            state.address = v
        },
        setUsePersonalLocationAsOfficeLocation(state, v) {
            state.usePersonalLocationAsOfficeLocation = v
        },
        setOfficeZip(state, v) {
            state.officeZip = zenkakuToHankaku(v)
        },
        setOfficePrefCd(state, v) {
            state.officePrefCd = v
        },
        setOfficeCity(state, v) {
            state.officeCity = v
        },
        setOfficeAddress(state, v) {
            state.officeAddress = v
        },
        setTel(state, v) {
            v = zenkakuToHankaku(v)
            state.tel = v.toString().replace(/\s+/g,'')
        },

        // STEP2
        setEmployeeTypes(state, v) {
            state.employeeTypes = v
        },
        setUnitPrice(state, v) {
            state.unitPrice = zenkakuToHankaku(v)
        },
        setWorkingTimes(state, v) {
            state.workingTimes = v
        },
        setJobCategories(state, v) {
            state.jobCategories = v
        },
        setLocations(state, v) {
            state.locations = v
        },
        setWorkStyles(state, v) {
            state.workStyles = v
        },

        // STEP3
        setProfessionalLevelSkills(state, v) {
            state.professionalLevelSkills = v
        },
        setBusinessLevelSkills(state, v) {
            state.businessLevelSkills = v
        },
        setBasicLevelSkills(state, v) {
            state.basicLevelSkills = v
        },
        removeProfessionalLevelSkill(state, v) {
            state.professionalLevelSkills = state.professionalLevelSkills.filter(a => {
                return !(a.code == v.code && a.name == v.name && a.parentCode == v.parentCode)
            })
        },
        removeBusinessLevelSkill(state, v) {
            state.businessLevelSkills = state.businessLevelSkills.filter(a => {
                return !(a.code == v.code && a.name == v.name && a.parentCode == v.parentCode)
            })
        },
        removeBasicLevelSkill(state, v) {
            state.basicLevelSkills = state.basicLevelSkills.filter(a => {
                return !(a.code == v.code && a.name == v.name && a.parentCode == v.parentCode)
            })
        },

        setCertifications(state, v) {
            state.certifications.push(v)
        },
        addCertification(state, v) {
            state.certifications.push(v)
        },
        removeCertification(state, v) {
            state.certifications = state.certifications.filter((a) => {
                if (a.code == v.code && a.name == v.name) return false;
                return true;
            })
        },
        // STEP4
        addWorkExperience(state) {
            state.workExperiences.push({
                businessTypes: [],
                jobCategories: [],
                jobTypes: [],
                contractType: null,
                periodStartYear: { code: "", name: "" },
                periodStartMonth: { code: "", name: "" },
                periodEndYear: { code: "", name: "" },
                periodEndMonth: { code: "", name: "" },
                periodCurrent: false,
                companyName: "",
                description: "",
                skills: [],
                knownBusinessTypes: []
            })
        },

        updateWorkExperience(state, { index, value }) {
            state.workExperiences.splice(index, 1, value)
        },

        removeWorkExperience(state, index) {
            state.workExperiences.splice(index, 1)
        },

        // STEP5
        setCatchPhrase(state, v) {
            state.catchPhrase = v
        },
        setSelfPromotion(state, v) {
            state.selfPromotion = v
        },

        // STEP6
        setSelfCheck(state, { question, answer }) {
            const obj = {
                question: question,
                answer: answer
            };

            for (const i in state.selfChecks) {
                const oldAnswer = state.selfChecks[i]
                if (oldAnswer.question == question) {
                    state.selfChecks.splice(i, 1, obj)
                    return
                }
            }
            state.selfChecks.push(obj)
        },

        setAdditionalQuestion(state, { question, answer }) {
            if(question && answer){
                const obj = {
                    question: question,
                    answer: answer
                };
    
                for (const i in state.additionalQuestions) {
                    const oldAnswer = state.additionalQuestions[i]
                    if (oldAnswer.question == question) {
                        state.additionalQuestions.splice(i, 1, obj)
                        return
                    }
                }
                state.additionalQuestions.push(obj)
            }else{
                state.additionalQuestions = []
            }
        },

        setCustomQuestionAnswer(state, { id, answer }) {

            // なぜか最初にudefinedが入ったものが生成されてしまうため
            if ((0 in state.customQuestionAnswers) == true) {
                if (state.customQuestionAnswers[0].answer == undefined) {
                    state.customQuestionAnswers.shift();
                }
            }
            
            if (Array.isArray(answer)) {
                // 重複を削除したリスト
                var deleteMultiple = answer.filter(function (x, i, self) {
                    return self.indexOf(x) === i;
                });

                // 重複のみをリスト
                var multiple = answer.filter(function (x, i, self) {
                    return self.indexOf(x) !== self.lastIndexOf(x);
                });
                console.log(multiple);
                console.log(deleteMultiple);
                if (multiple.length !== 0) {
                    var val = multiple[0];
                    var index = deleteMultiple.indexOf(val);
                    deleteMultiple.splice(index, 1)
                    console.log(deleteMultiple);
                    answer = deleteMultiple;
                }
                
            }

            const obj = {
                id: id,
                answer: answer
            };
            for (const i in state.customQuestionAnswers) {
                const oldAnswer = state.customQuestionAnswers[i]
                if (oldAnswer.id == id) {
                    state.customQuestionAnswers.splice(i, 1, obj)
                    return
                }
            }
            state.customQuestionAnswers.push(obj)
        },

        addPortfolio(state) {
            state.portfolios.push({
                portfolio: null,
                periodStartYear: { code: "", name: "" },
                periodStartMonth: { code: "", name: "" },
                periodEndYear: { code: "", name: "" },
                periodEndMonth: { code: "", name: "" },
                periodCurrent: false,
                jobTypes: [],
                title: "",
                description: "",
                referencePrices: null,
                url: ""
            })
        },

        updatePortfolio(state, { index, value }) {
            state.portfolios.splice(index, 1, value)
        },

        removePortfolio(state, index) {
            state.portfolios.splice(index, 1)
        },

        clearPortfolio(state) {
            state.portfolios.splice(0, 1)
        },

        setPortfolioUpdateFlag(state, v) {
            state.portfolioUpdateFlag = v
        },

        setMailMagazine(state, v) {
            state.mailMagazine = v
        },

        setScout(state, v) {
            state.scout = v
        },

        setNewMail(state, v) {
            state.newMail = v
        },

        setTemporaryProfileId(state, v) {
            state.temporaryProfileId = v
        },

        setMessageUnreadFlg(state, v) {
            state.messageUnreadFlg = v
        },

        setCertificationText(state, v) {
            state.certificationText = v
        },

        setProfile(state, v) {
            if (v.status != 429) {
                state.profileImage = v.profileImage
                state.memberKind = v.memberKind
                if (typeof (v.memberKind) == 'string') {
                    console.info("memberKindがstring")
                    state.memberKind = {
                        'code': '01',
                        'name': '個人',
                    }
                }
                state.companyName = v.companyName
                state.companyNameKana = v.companyNameKana
                state.presidentName = v.presidentName
                state.presidentNameKana = v.presidentNameKana
                state.familyNameKana = v.familyNameKana
                state.nameKana = v.nameKana
                state.familyName = v.familyName
                state.name = v.name
                state.useNameAsDisplayName = v.useNameAsDisplayName
                state.displayName = v.displayName
                state.gender = v.gender
                if (typeof (v.gender) == 'string') {
                    console.info("genderがstring")
                    state.gender = {
                        'code': '01',
                        'name': '男性',
                    }
                }
                state.birthdate = v.birthdate
                state.zip = v.zip
                state.prefCd = v.prefCd
                state.city = v.city
                state.address = v.address
                state.officeZip = v.officeZip
                state.officePrefCd = v.officePrefCd
                state.officeCity = v.officeCity
                state.officeAddress = v.officeAddress

                // 法人かつ住所と会社の住所が違っていたらチェックを外す
                if (
                    state.memberKind.code == '02'
                    &&
                    v.usePersonalLocationAsOfficeLocation == true
                    &&
                    !(state.zip == state.officeZip
                        &&
                        state.prefCd
                        &&
                        state.officePrefCd
                        &&
                        state.prefCd.code == state.officePrefCd.code
                        &&
                        state.city == state.officeCity
                        &&
                        state.address == state.officeAddress
                    )) {
                    state.usePersonalLocationAsOfficeLocation = false
                } else {
                    state.usePersonalLocationAsOfficeLocation = v.usePersonalLocationAsOfficeLocation
                }

                // state.usePersonalLocationAsOfficeLocation = v.usePersonalLocationAsOfficeLocation


                state.tel = v.tel
                state.employeeTypes = v.employeeTypes ? v.employeeTypes : []  // サーバー側が実装されたら外しても良い
                state.unitPrice = v.unitPrice
                state.unitPriceMin = v.unitPriceMin
                state.unitPriceMax = v.unitPriceMax
                state.workingTimes = v.workingTimes
                if (v.workingTimes && v.workingTimes.length > 0 && typeof (v.workingTimes[0]) == 'string') {
                    console.info("TODO: API レスポンス profile.workingTimesがstring")
                    state.workingTimes = []
                }
                state.jobCategories = v.jobCategories
                console.log(v)
                if (v.jobCategories && v.jobCategories.length > 0 && typeof (v.jobCategories[0]) == 'string') {
                    console.info("TODO: API レスポンス profile.JobCategoriesがstring")
                    state.jobCategories = []
                }
                state.locations = v.locations
                if (v.locations && v.locations.length > 0 && typeof (v.locations[0]) == 'string') {
                    console.info("TODO: API レスポンス profile.locationsがstring")
                    state.locations = []
                }
                state.workStyles = v.workStyles
                if (v.workStyles && v.workStyles.length > 0 && typeof (v.workStyles[0]) == 'string') {
                    console.info("TODO: API レスポンス profile.workStylesがstring")
                    state.workStyles = []
                }
                state.professionalLevelSkills = v.professionalLevelSkills
                state.businessLevelSkills = v.businessLevelSkills
                state.basicLevelSkills = v.basicLevelSkills
                state.certifications = v.certifications
                state.workExperiences = v.workExperiences
                if (v.workExperiences && v.workExperiences.length > 0) {
                    if (!v.workExperiences[0].businessTypes) {
                        console.info("TODO: API レスポンス profile.workExperiencesが古い")
                        state.workExperiences = []
                    }
                }
                state.catchPhrase = v.catchPhrase
                state.selfPromotion = v.selfPromotion
                state.selfChecks = v.selfChecks
                state.isRegisteredInvoice = v.isRegisteredInvoice
                state.portfolios = v.portfolios
                state.mailMagazine = v.mailMagazine
                state.scout = v.scout
                state.newMail = typeof v.newMail == 'undefined' ? false : v.newMail
                state.loaded = true

                state.verifyStatus = v.verifyStatus
                state.bankAccountStatus = v.bankAccountStatus

                state.messageUnreadFlg = v.messageUnreadFlg
                state.rt = v.rt

                state.memberId = v.memberId
                state.displayNps = v.displayNps
                state.evaluateAverage = v.evaluateAverage
            } else {
                return
            }
        },
        resetState(state) {
            state.loaded = false;
            state.birthdate = null;
            state.profileImage = null;
            state.memberKind = {
                'code': '01',
                'name': '個人'
            };
            state.companyName = "";
            state.companyNameKana = "";
            state.presidentName = "";
            state.presidentNameKana = "";
            state.familyNameKana = "";
            state.nameKana = "";
            state.familyName = "";
            state.name = "";
            state.useNameAsDisplayName = false;
            state.displayName = "";
            state.gender = {
                'code': '',
                'name': ''
            };
            state.zip = "";
            state.prefCd = "";
            state.city = "";
            state.address = "";
            state.usePersonalLocationAsOfficeLocation = false;
            state.officeZip = "";
            state.officePrefCd = "";
            state.officeCity = "";
            state.officeAddress = "";
            state.tel = "";
            state.employeeTypes = [];
            state.unitPrice = "";
            state.workingTimes = [];
            state.jobCategories = [];
            state.locations = [];
            state.workStyles = [];
            state.professionalLevelSkills = [];
            state.businessLevelSkills = [];
            state.basicLevelSkills = [];
            state.certifications = [];
            state.workExperiences = [];
            state.catchPhrase = "";
            state.selfPromotion = "";
            state.selfChecks = [];
            state.isRegisteredInvoice = false;
            state.portfolios = [];
            state.mailMagazine = true;
            state.scout = true;
            state.newMail = true;
            state.temporaryProfileId = null;
            state.unitPriceMin = null;
            state.unitPriceMax = null;
            state.messageUnreadFlg = false;
            state.customQuestionAnswers = [];
        },
        setUpdateFlg(state, v) {
            state.updateFlg = v
        },
        setShowVerifyModalFlg(state, v) {
            state.showVerifyModalFlg = v
        },
        setShowNDAModalFlg(state, v) {
            state.showNDAModalFlg = v
        },
        setShowNDAMessageFlg(state, v) {
            state.showNDAMessageFlg = v
        },
        setShowSpModalBack(state, v) {
            state.showSpModalBack = v
        },
        setRt(state, v) {
            state.rt = v
        },
        setMemberId(state, v) {
            state.memberId = v
        },
        removeSkill(state, value) {
            if (value && state.workExperiences) {
                for (let i = 0; i < state.workExperiences.length; i++) {
                    console.log(state.workExperiences[i].skills)
                    state.workExperiences[i].skills = state.workExperiences[i].skills.filter((skill) => skill != value)
                    console.log(state.workExperiences[i].skills)
                }
            }
        },
        setUpdateOnlyPortfolioFlg(state, value) {
            state.updateOnlyPortfolioFlg = value
        },
        setFromSNS(state, value) {
            state.fromSNS = value
        },
        setFromRegister(state, value) {
            state.fromRegister = value
        },
        setIsPreventDefection(state, boolean) {
            state.isPreventDefection = boolean
            if (state.isPreventDefection === true) {
                console.log('mutation onbeforeunload')
                window.onbeforeunload = function () {
                    return "このページを離れますがよろしいですか？";
                };
            } else {
                console.log('mutation onbeforeunload null')
                window.onbeforeunload = null
            }
        },
        setFalseNpsFlag(state, value) {
            state.displayNps = false
        },
        setIsFromNextPage(state,boolean){
            state.isFromNextPage = boolean
        },
				setViaEntry(state,bool){ state.viaEntry = bool }
    },
    actions: {
        getProfile({ commit }) { /* eslint no-unused-vars:0 */
            return new Promise((resolve, reject) => {
                MemberAPI.getProfile().then((profile) => {
                    commit('setProfile', profile)
                    resolve()
                })
                    .catch(e => {
                        console.log(e)
                        reject(e)
                    })
            });
        },
        getProfileFromSNSProfile({ commit }) { /* eslint no-unused-vars:0 */
            return new Promise((resolve) => {
                (async () => {
                    const profile = await MemberAPI.getSNSProfile()

                    if (!profile.identifier) {
                        resolve()
                    }

                    if (profile.profile.last_name_kanji) {
                        commit('setFamilyName', profile.profile.last_name_kanji)
                    }
                    else if (profile.profile.last_name) {
                        commit('setFamilyName', profile.profile.last_name)
                    }

                    if (profile.profile.first_name_kanji) {
                        commit('setName', profile.profile.first_name_kanji)
                    }
                    else if (profile.profile.first_name) {
                        commit('setName', profile.profile.first_name)
                    }

                    if (profile.profile.last_name_kana) {
                        commit('setFamilyNameKana', profile.profile.last_name_kana)
                    }

                    if (profile.profile.first_name_kana) {
                        commit('setNameKana', profile.profile.first_name_kana)
                    }

                    if (profile.profile.image_url) {
                        commit('setProfileImage', profile.profile.image_url)
                    }

                    if (profile.profile.user_name) {
                        commit('setDisplayName', profile.profile.user_name)
                    }

                    if (profile.profile.postal_code) {
                        commit('setZip', profile.profile.postal_code)
                    }

                    resolve();
                })();
            });
        },
        postProfileFirstTime({ commit, state, getters }) { /* eslint no-unused-vars:0 */
            // プロフィール登録初回時
            return new Promise((resolve, reject) => {
                (async () => {
                    let params = {}
                    console.log(document.cookie)
                    console.log(getters.getRtFromCookie)
                    if (getters.getRtFromCookie) {
                        params = { rt: state.rt }
                    }

                    const new_profile = await MemberAPI.postProfile(params).catch((e) => {
                        console.log(e)
                        reject(e)
                    })

                    if (state.fromSNS) {
                        resolve()
                    } else {
                        commit('setProfile', new_profile)
                        commit('setIsPreventDefection', false)
                        commit('setFromRegister', false)
                        resolve(new_profile)
                    }
                })();
            });
        },
        postProfile({ commit, state, getters }) { /* eslint no-unused-vars:0 */
            // プロフィール更新
            return new Promise((resolve, reject) => {
                (async () => {

                    // cookieがあればそれを追加
                    getters.getRtFromCookie
                    const params = buildPostProfileParams(state, getters)
                    console.log(params)
                    const new_profile = await MemberAPI.postProfile(params)
                    .catch((e) => {
                        console.log(e)
                        reject(e)
                    })
                    commit('setProfile', new_profile)
                    commit('setIsPreventDefection', false)
                    commit('setFromRegister', false)

                    await MemberAPI.getUserAttribute()
                    .then((res) => {
                        window._uaProfile = res;
                        console.log('updated user attribute', window._uaProfile);
                    })
                    .catch((e) => {
                        console.log("function error", e);
                    })

                    resolve(new_profile)
                })();
            });
        },
        postTemporaryProfile({ commit, state, getters }) {
            // クッキーから経路取得
            getters.getRtFromCookie

            return new Promise((resolve) => {
                (async () => {
                    const params = buildPostProfileParams(state)
                    const result = await PublicAPI.postProfile(params)
                    commit('setTemporaryProfileId', result.temporaryProfileId)
                    commit('setFromRegister', false)
                    resolve()
                })();
            });
        },
        finalizeTemporaryProfile(context, temporaryProfileId) {
            return new Promise((resolve) => {
                (async () => {
                    const result = await MemberAPI.postProfileTemporaryProfileId(temporaryProfileId)
                    resolve()
                })();
            });
        },
				// 非会員エントリー用
				postTmpMemberWithEntry({ commit, state, getters },{email,birthday}) { /* eslint no-unused-vars:0 */
					return new Promise((resolve) => {
						(async () => {
							const params = buildPostProfileParams(state)
							params["email"] = email
							params["birthday"] = birthday
							params["rt"] = state.rt

							const result = await PublicAPI.postTmpProfileWithEntry(params)
							commit('setTemporaryProfileId', result.temporaryProfileId)
							commit('setFromRegister', false)
							resolve()
						})();
					});	
				},

				// 通常会員エントリー用
				postTmpMember({ commit, state, getters },{email,birthday}) {
					// クッキーから経路取得
					getters.getRtFromCookie
					return new Promise((resolve) => {
						(async () => {
							const params = { 
								email:email,
								birthday:birthday,
								rt:state.rt
							}
						const result = await PublicAPI.postTmpProfile(params)
							commit('setTemporaryProfileId', result.temporaryProfileId)
							commit('setFromRegister', false)
							resolve()
						})();
					});
				},

				confirmMember({ commit, state, getters },{username,confirmation_code}) {
					return new Promise((resolve, reject) => {
						(async () => {
							const result = await PublicAPI.postMemberConfirmation(username,confirmation_code)
							.then((r)=>{
									console.log(r)
									commit('setViaEntry',r.entry)
									resolve()
								}).catch((e) => {
									console.log(e)
									reject(e)
								})
							})();
					});
				},
                deletePortfolio({ commit, state }, index) {
        
                    return new Promise((resolve, reject) => {
        
                        const portfolioId = state.portfolios[index].portfolioId;
    
                        MemberAPI.deletePortfolio(portfolioId).catch((e) => {
                            console.log(e)
                            reject(e)
                        })
                        .then(() => {
                            commit('removePortfolio', index);
                            resolve();
                        })
                    })
                }
    },
    getters: {
        isPortfolioBlank(state) {
            if (state.portfolios && state.portfolios.length != 0) {
                let p = state.portfolios[0];
                if (
                    p &&
                    p.description == "" &&
                    p.jobTypes.length == 0 &&
                    p.periodCurrent == false &&
                    p.periodEndMonth &&
                    p.periodEndMonth.code == "" &&
                    p.periodEndYear &&
                    p.periodEndYear.code == "" &&
                    p.periodStartMonth &&
                    p.periodStartMonth.code == "" &&
                    p.periodStartYear &&
                    p.periodStartYear.code == "" &&
                    p.portfolio == null &&
                    p.referencePrices == null &&
                    p.title == "" &&
                    p.url == ""
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },
        canAddWorkExperience(state) {
            if (state.workExperiences.length >= 20) return false;

            return true;
        },
        // クッキーから経路取得
        getRtFromCookie(state) {
            console.log(document.cookie)
            if (document.cookie) {
                if (document.cookie.match('rt=1')) {
                    state.rt = 1
                    return true
                } else if (document.cookie.match('rt=2')) {
                    state.rt = 2
                    return true
                } else if (document.cookie.match('rt=3')) {
                    state.rt = 3
                    return true
                } else if (document.cookie.match('rt=4')) {
                    state.rt = 4
                    return true
                } else if (document.cookie.match('rt=5')) {
                    state.rt = 5
                    return true
                } else if (document.cookie.match('rt=6')) {
                    state.rt = 6
                    return true
                } else if (document.cookie.match('rt=7')) {
                    state.rt = 7
                    return true
                } else if (document.cookie.match('rt=8')) {
                    state.rt = 8
                    return true
                } else if (document.cookie.match('rt=9')) {
                    state.rt = 9
                    return true
                } else {
                    state.rt = null
                    return false
                }
            } else {
                state.rt = null
                return false
            }
        },
        syncSkills(state) {
            console.log(state)
            // 現在のスキルを一つにまとめる
            let currentSkills = []
            if (state.professionalLevelSkills && state.professionalLevelSkills.length > 0) {
                for (var i = 0; i < state.professionalLevelSkills.length; i++) {
                    currentSkills.push(state.professionalLevelSkills[i])
                }
            }
            if (state.businessLevelSkills && state.businessLevelSkills.length > 0) {
                for (var h = 0; h < state.businessLevelSkills.length; h++) {
                    currentSkills.push(state.businessLevelSkills[h])
                }
            }
            if (state.basicLevelSkills && state.basicLevelSkills.length > 0) {
                for (var g = 0; g < state.basicLevelSkills.length; g++) {
                    currentSkills.push(state.basicLevelSkills[g])
                }
            }

            // 職歴のスキルが登録したスキルになかったらそのスキルを省く
            if (state.workExperiences && state.workExperiences.length > 0 && currentSkills && currentSkills.length > 0) {
                for (var j = 0; j < state.workExperiences.length; j++) {
                    for (var k = 0; k < state.workExperiences[j].skills.length; k++) {
                        var count = 0
                        for (var l = 0; l < currentSkills.length; l++) {
                            if (state.workExperiences[j].skills[k].name == currentSkills[l].name &&
                                state.workExperiences[j].skills[k].parentCode == currentSkills[l].parentCode) {
                                count += 1
                            }
                        }
                        // 現在のスキルになかったらそのスキルをnullにする
                        if (count == 0) {
                            console.log(state.workExperiences[j].skills[k])
                            state.workExperiences[j].skills[k] = null
                        }
                    }
                    // nullにしたスキルを省く
                    state.workExperiences[j].skills = state.workExperiences[j].skills.filter(skills => skills != null)
                }
            }
        },
        SelfPromotionTemplate(state) {

            let resultText = "";

            const summary = "【概要】\nこれまで×××××の業界で、×××××を経験してきました。\n×××××が強みで、×××××に貢献できます。";
            const valuedIdeas = "【大事にしている考え方】\n・×××××\n・×××××";

            resultText = summary + "\n\n" + valuedIdeas + "\n\n";

            let workExperienceText = "【経験・実績】";

            if (state.workExperiences && state.workExperiences.length > 0) {

                for (var i = 0; i < state.workExperiences.length; i++) {

                    if( state.workExperiences[i].companyName != "" && state.workExperiences[i].companyName != null ) {

                        workExperienceText = workExperienceText + "\n・会社名：" + state.workExperiences[i].companyName;
                    
                    } else {

                        workExperienceText = workExperienceText + "\n・会社名：×××××";
                    }

                    if (state.workExperiences[i].jobTypes && state.workExperiences[i].jobTypes.length > 0) {
                        
                        let jobTypeText = ""

                        for (var h = 0; h < state.workExperiences[i].jobTypes.length; h++) {

                            if( h >= 1 ) {
                                jobTypeText = jobTypeText + "、";
                            }

                            jobTypeText = jobTypeText + state.workExperiences[i].jobTypes[h].name;
                            
                        }

                        workExperienceText = workExperienceText + "\n" + jobTypeText + "として ×××××という実績を残しました。\n";
                    
                    } else {

                        workExperienceText = workExperienceText + "\n×××××として ×××××という実績を残しました。\n"
                    }
                }

                if( state.workExperiences.length < 3 ) {

                    for (var g = 0; g < 3 - state.workExperiences.length; g++) {

                        workExperienceText = workExperienceText + "\n・会社名：×××××\n×××××として ×××××という実績を残しました。\n";
                    }
                }
            }

            resultText = resultText + workExperienceText + "\n";

            let skillText = "【ビジネススキル】";
            let skillCount = 0;

            if (state.professionalLevelSkills && state.professionalLevelSkills.length > 0) {
                for (var k = 0; k < state.professionalLevelSkills.length; k++) {
                    skillText = skillText + "\n" + "・" + state.professionalLevelSkills[k].name;
                    skillCount++;
                }
            }
            if (state.businessLevelSkills && state.businessLevelSkills.length > 0) {
                for (var l = 0; l < state.businessLevelSkills.length; l++) {
                    skillText = skillText + "\n" + "・" + state.businessLevelSkills[l].name;
                    skillCount++;
                }
            }
            if (state.basicLevelSkills && state.basicLevelSkills.length > 0) {
                for (var m = 0; m < state.basicLevelSkills.length; m++) {
                    skillText = skillText + "\n" + "・" + state.basicLevelSkills[m].name;
                    skillCount++;
                }
            }

            if( skillCount < 2 ) {

                for (var n = 0; n < 3 - state.workExperiences.length; n++) {

                    skillText = skillText + "\n・×××××";
                }
            }

            resultText = resultText + skillText + "\n\n";

            const areaText = "【支援可能な領域】\n・×××××\n・×××××\n・×××××\n・×××××";

            resultText = resultText + areaText;

            return resultText;
        },
    },
}