<template>
  <!-- <div class="menu--wrapper" :class="{ active: showPcMenu}"  @click.self="$emit('closeSpMenuTrigger')"> -->
    <div class="menu--header_pc no-pcmenu-close" ref="pcMenu" :class="{ active: showPcMenu}">
      <template v-if="develop">
        <!-- PC版マイページ以外ヘッダーから呼ばれる右側のサイドメニュー -->
      </template>
      <!-- ===== mypage_nav_menu/ ===== -->
      <div class="">
      <!-- <div class="mypage_nav_menu mypage_nav_menu_lv1"> -->
      <template v-if="isLoggedIn">
        <ul id="global_nav_menu_list" class="mypage_nav_menu_list global_nav_menu_list">
          <li>
            <a
              href="/mypage"
              class="mypage"
              >マイページ</a
            >
          </li>
          <li>
            <a
              href="/mypage/setting/profile"
              class="setting_profile"
              >プロフィール</a
            >
          </li>
          <li class="list--menu_has_child" >
            <a
              v-on:mouseover="accountSettingHover = true" v-on:mouseleave="accountSettingHover = false"
              ref="accountSettingMenu"
              class="setting"
              >アカウント設定
                <div 
                  v-show="accountSettingHover || accountSettingClicked" 
                  class="menu--add menu--account_setting"
                >
                  <ul>
                    <li>
                      <a
                        href="/mypage/setting/basic"
                        class="setting_basic"
                        >基本情報</a
                      >
                    </li>
                    <li>
                      <a
                        href="/mypage/setting/mute"
                        class="setting_mute"
                        >非公開設定</a
                      >
                    </li>
                    <!-- <li>
                      <a
                        href="/mypage/setting/verify"
                        class="setting_verify"
                        >本人確認書類</a
                      >
                    </li>
                    <li>
                      <a
                        href="/mypage/setting/bank"
                        class="setting_bank"
                        >振込先口座情報</a
                      >
                    </li>
                    <li>
                      <a
                        href="/mypage/setting/invoice"
                        class="setting_invoice"
                        >インボイス</a
                      >
                    </li> -->
                    <li>
                      <a
                        href="/mypage/setting/nda"
                        class="setting_nda"
                        >機密保持の再確認</a
                      >
                    </li>
                    <li>
                      <a
                        href="/mypage/setting/selfcheck"
                        class="setting_selfcheck"
                        >セルフチェック</a
                      >
                    </li>
                  </ul>
                </div>
              </a>
              <!-- href="/mypage/setting" -->
          </li>
          <li class="list--menu_has_child">
            <a
              href="/mypage/works"
              class="manage"
              >案件管理</a
            >
          </li>
          <!-- <li>
            <a
              href="/mypage/reward"
              class="money"
              >報酬管理</a
            >
          </li> -->
          <li>
            <a
              href="/job"
              class="search"
              >案件検索</a
            >
          </li>
          <li>
            <a
              href="/interest"
              class="interest"
              >気になるリスト</a
            >
          </li>
          <li>
            <a
              href="/magazine"
              class="magazine"
              >スキイキマガジン</a
            >
          </li>
          <li>
            <a
              href="/topic"
              class="topic"
              >お知らせ</a
            >
          </li>
          <li>
            <a href="/logout" class="logout">
              <span>ログアウト</span>
            </a>    
          </li>
        </ul>
        <div v-if="progress" class="wrapper--graph">
          <p class="text--progress">プロフィール入力状況</p>
          <div class="graph--progress" :style="progressGraphStyle">
            {{ progress }}<span class="percent">％</span>
          </div>
        </div>
        <div v-else class="wrapper--graph">
          <p class="text--progress">プロフィール入力状況</p>
          <div class="graph--progress zero_percent">
            0<span class="percent">％</span>
          </div>
        </div>
        <!-- <div v-else class="wrapper--graph" style="height: 260px;">
        </div> -->
      </template>
      <template v-else>
        <ul id="global_nav_menu_list" class="mypage_nav_menu_list global_nav_menu_list">
          <li>
            <a
              href="/job"
              class="search"
              >案件検索</a
            >
          </li>
          <li>
            <a
              href="/interest"
              class="interest"
              >気になるリスト</a
            >
          </li>
          <li>
            <a
              href="/magazine"
              class="magazine"
              >スキイキマガジン</a
            >
          </li>
          <li>
            <a
              href="/topic"
              class="topic"
              >お知らせ</a
            >
          </li>
          <li>
            <a href="/login" class="login">
              <span>ログイン</span>
            </a>    
          </li>
        </ul>
      </template>

      <ul id="global_under_nav_menu_list" class="mypage_under_nav_menu_list">
        <li>
          <a href="/guide">使い方ガイド</a>
        </li>
        <li>
          <a href="/contact">お問い合わせ</a>
        </li>
        <!-- <li>
          <a href="/terms">会員規約</a>
        </li>
        <li>
          <a href="/privacy1">個人情報の取り扱いについて</a>
        </li> -->
      </ul>
      </div>
      <!-- ===== /mypage_nav_menu ===== -->
    </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "HeaderMenuPC",
  data() {
    return {
      accountSettingHover: false,
      accountSettingClicked: false,
    };
  },
  props: {
    showPcMenu: Boolean,
  },
  components: {},
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    progress() {
      return this.$store.state.profileStatus.progress;
    },
    progressGraphStyle() {
      const percent = this.$store.state.profileStatus.progress;
      return "background-image: radial-gradient(#f2f2f2 47%, transparent 48%), conic-gradient(#15C4B9 0% " + percent + "%, #d9d9d9 " + percent +"% 100%);";
    },
  },
  methods: {
    handleClick(event) {
      const accountSettingMenu = this.$refs.accountSettingMenu;

      if( !accountSettingMenu ) {
        return;
      }
      // クリックされたのが「アカウント設定」の領域内だったら
      if (accountSettingMenu.contains(event.target)) {
        this.accountSettingClicked = true;
      } else {
        this.accountSettingClicked = false;
      }

      const pcMenu = this.$refs.pcMenu;
      if( pcMenu.contains(event.target) ) {
        return;
      }

      // クリックされたのがメニューとハンバーガーアイコンの領域外だったら
      if (!event.target.classList.contains('no-pcmenu-close')) {
        this.$emit('closeSpMenuTrigger');
      }
    },
  },
  mounted() {
    if(!this.$store.state.profileStatus.profile) {
      this.$store.dispatch("profileStatus/getProfileStatus").catch((e) => {
        console.log(e);
      });
    }
    if (!this.$store.state.profile.memberId) {
      this.$store.dispatch("profile/getProfile")
      .catch((e) => {
        console.log(e);
      });
    }
    // クリックイベント検知
    document.addEventListener('click', this.handleClick);
  },
  beforeDestroy() {
    // イベントリスナーを解除
    document.removeEventListener('click', this.handleClick);
  },
};
</script>

